import { useQuery } from '@tanstack/react-query'

import { GetKpis, Kpis, UseGetKpis } from 'models/prebooks'
import { getKpis } from 'services/prebooks'
import { GET_KPIS } from 'config/queries'

const useGetKpis = ({ from, to, carparkCode, channelCodes, centerType }: GetKpis): UseGetKpis => {
  const queryKey = [GET_KPIS, from, to, carparkCode, channelCodes, centerType]

  const {
    data: response,
    isLoading,
    isPreviousData,
  } = useQuery({
    queryKey,
    queryFn: () => getKpis({ from, to, carparkCode, channelCodes, centerType }),
    refetchOnMount: true,
    keepPreviousData: true,
  })

  const result: Kpis = response?.data ?? {}
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading: isLoading || isPreviousData }
}

export default useGetKpis
