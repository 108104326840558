import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Search from '@mui/icons-material/Search'

import CarparkSearcher from 'components/molecules/locations-searcher'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import { SearchCarparksFormFields, SearchPrebooksFormFields } from 'models/prebooks'
import { searchPrebooksSchema } from 'validations/prebooks'
import { DateFormats, FontSizes } from 'config/constants'
import { CenterType } from 'models/center'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import { ClearFiltersButton } from 'components/atoms/clear-filter-button'

import { PrebooksSearcherFormContainer } from './styled'

const CENTER_OPTIONS = [
  { value: 'Todos', text: 'Todos' },
  { value: CenterType.AGGREGATION, text: 'Agregación' },
  { value: CenterType.TELPARK, text: 'Telpark' },
]

const defaultValues: SearchCarparksFormFields = {
  from: '',
  to: '',
  channelCodes: [],
  centerType: CENTER_OPTIONS[0].value,
  carparkCode: undefined,
}

interface Args {
  onSubmit: (formData: SearchPrebooksFormFields) => void
}

const PrebooksSearcherForm: React.FC<Args> = ({ onSubmit }): React.JSX.Element => {
  const { t } = useTranslation()
  const [reRenderKey, setReRenderKey] = useState(0)
  const [storedData, setStoredData] = useState<SearchPrebooksFormFields | null>(null)
  const [canSend, setCanSend] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<SearchPrebooksFormFields>({
    defaultValues,
    resolver: yupResolver(searchPrebooksSchema),
  })

  useEffect(() => {
    const storage = sessionStorage.getItem('prebooks_search')
    if (storage) {
      const parsedStorage = JSON.parse(storage)
      for (const key in parsedStorage) {
        setValue(key as keyof SearchCarparksFormFields, parsedStorage[key])
      }
      setStoredData(parsedStorage)
      onSubmit(parsedStorage)
    }
    // eslint-disable-next-line
  }, [])

  const handleClearFilters = () => {
    sessionStorage.removeItem('prebooks_search')
    setValue('centerType', CENTER_OPTIONS[0].value)
    setValue('channelCodes', defaultValues.channelCodes)
    setValue('from', defaultValues.from)
    setValue('to', defaultValues.to)
    setValue('carparkCode', defaultValues.carparkCode)
    setReRenderKey(Math.random()) // Needed to restore carpark searcher value
    setStoredData(null)
  }

  const submitHandler = useCallback((values: SearchPrebooksFormFields) => {
    sessionStorage.setItem('prebooks_search', JSON.stringify({ ...values }))
    onSubmit(values)
  }, [onSubmit])

  const [watchFrom, watchTo, carparkWatch] = watch(['from', 'to', 'carpark'])

  useEffect(() => {
    setValue('carparkCode', carparkWatch?.carparkCode || undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carparkWatch]
  )

  useEffect(() => {
    if (watchFrom && watchTo) {
      setCanSend(true)
    } else {
      setCanSend(false)
    }
  }, [watchFrom, watchTo])

  return (
    <PrebooksSearcherFormContainer container spacing={2} mt={5}>
      <Grid item xs={12}>
        <CarparkSearcher
          key={reRenderKey}
          name='carpark'
          fullValue
          control={control}
          initialValue={storedData?.carpark}
          error={errors.carparkCode}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputSelect
          name='centerType'
          control={control}
          label={t('Tipo centro')}
          size='full'
          error={errors.centerType}
          options={CENTER_OPTIONS}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputDateTimePicker
          key={reRenderKey}
          onlyDate
          label={t('Desde*')}
          name='from'
          control={control}
          error={errors.from}
          size='full'
          pattern={DateFormats.isoCeros}
        />
      </Grid>
      <Grid item xs={3}>
        <ControllerInputDateTimePicker
          key={reRenderKey}
          onlyDate
          label={t('Hasta*')}
          name='to'
          control={control}
          error={errors.to}
          size='full'
          pattern={DateFormats.isoCeros}
        />
      </Grid>
      {/* <Grid item xs={3}> */}
      {/*   <ControllerInputSelectChannel control={control} name='channelCodes' label={t`Canales`} size='full' /> */}
      {/* </Grid> */}
      <Grid item xs={3} display={'flex'} flexDirection={'column'} alignItems={'flex-end'} gap={2}>
        <Button
          fullWidth
          onClick={handleSubmit(submitHandler)}
          color='primary'
          type='submit'
          disabled={!canSend}
          variant='contained'
          startIcon={<Search />}
        >
          <Typography mt={0.5} color='white' fontSize={FontSizes.base}>
            {t('Filtrar')}
          </Typography>
        </Button>
        <ClearFiltersButton handleClearFilters={handleClearFilters} color={'default'} />
      </Grid>
    </PrebooksSearcherFormContainer>
  )
}

export default PrebooksSearcherForm
