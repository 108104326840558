import { Version } from './version'

export interface Center {
  id: number
  carparkCode: string
  name: string
  province: string
  active: boolean
  rounding: number
  pricingType: CenterPricingType
  maxDaysEntry: number
  managementFee: number
  maxManagementFee: number
  lastMinuteFeeEnabled: boolean
  minutesBeforeLastMinuteFee: number
  lastMinuteFee: number
  minutesBeforeEntry: number
  telparkAppDiscount: number
  telparkWebDiscount: number
  easyPrebooksDiscount: number
  stockSize: number
  prebookLimitEnabled: boolean
  preblockLimitEnabled: boolean
  preblockLimit: number
  highDemandFeeEnabled: boolean
  highDemand50Fee: number
  highDemand80Fee: number
  createDate: string
  updateDate: string
  version: number
  originalID: number
  salesBlocked: boolean
  salesBlockedMinutes: number
  timezone: CenterTimezone
  showChannelDiscount: boolean
  showHighDemandFee: boolean
  showLastMinuteFee: boolean
  showManagementFee: boolean
  showRounding: boolean
  showVolumeDiscount: boolean
  curveId: number
  curveName: string
  curveAnticipationId: number
  centerType: CenterType
}

export type CenterVersion = Center & { user?: string }

export interface CentersFilters {
  active?: boolean
  search?: string
  centerType?: CenterType
}

export enum CenterPricingType {
  'PERIOD' = 'PERIOD',
  'DAILY' = 'DAILY',
}

export enum CenterType {
  'AGGREGATION' = 'AGGREGATION',
  'TELPARK' = 'TELPARK',
}

export enum CenterTimezone {
  MADRID = 'Europe/Madrid',
  CANARY = 'Atlantic/Canary',
  CEUTA = 'Africa/Ceuta',
  LISBON = 'Europe/Lisbon',
  MADEIRA = 'Atlantic/Madeira',
  AZORES = 'Atlantic/Azores',
  ANDORRA = 'Europe/Andorra',
}

export interface GetCenters {
  limit: number
  offset: number
  filters: CentersFilters
  sort: string
}

export interface UseGetCentersVersions {
  response: Array<Center>
  count: number
  isLoading: boolean
}

export interface UseGetOneCenterVersion {
  response: Center
  isLoading: boolean
}

export interface GetCentersVersions {
  id?: string
  limit: number
  offset: number
  sort: string
}

export interface GetCalendar {
  id: number
  month: string | number
  year: string | number
}

export interface UpdateCenter extends Partial<Center> {
  id: number
}

export interface UpdateCenterDay {
  centerId: number
  dayId: number
  price: number
}

export interface PostCenterDay {
  centerId: number
  date: string
  price: number
}

export interface CalendarDay {
  date: string
  price: number
  minDays?: number
  maxDays?: number
  id?: number
}

export interface UseGetCarparkCalendar {
  response: Array<CalendarDay>
  count: number
  isLoading: boolean
}

export type CenterDayVersion = CalendarDay & Version

export interface GetDayVersions {
  id: string
  limit: number
  offset: number
  sort: string
}

export interface GetOneDayVersion {
  id: string
  version: string
}

export interface UseGetDayVersions {
  response: CenterDayVersion[]
  count: number
  isLoading: boolean
}

export interface UseGetOneDayVersion {
  response?: CenterDayVersion
  isLoading: boolean
}
