import { FC } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import useGetOneCenterPeriodVersion from 'hooks/queries/centers/use-get-one-center-period-version'
import SpinnerLoading from 'components/atoms/spinner-loading'
import PageTitle from 'components/atoms/page-title'
import { DateFormats, FontFamily, FontSizes } from 'config/constants'
import { dateFormatter } from 'utils/date-formatter'
import PeriodAccordionContent from 'components/molecules/period-accordion-content'
import PeriodPriorityText from 'components/atoms/period-priority-text'

const CenterPeriodsHistoricShow: FC = () => {
  const { id = '', version = '' } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { response: period, isLoading } = useGetOneCenterPeriodVersion({ id, version })

  if (isLoading) {
    return <SpinnerLoading fullHeight />
  } else if (!period) {
    navigate(-1)
    return null
  }

  const translationOptions = {
    dateFrom: dateFormatter({ date: period.from, pattern: DateFormats.day }),
    dateTo: dateFormatter({ date: period.to, pattern: DateFormats.day }),
    interpolation: { escapeValue: false },
  }

  const title = `${t(
    'Periodo del {{ versionDateFrom }} al {{ versionDateTo }}',
    translationOptions
  )} - ${t`Versión`} ${period?.version}`

  return (
    <Box data-testid='period-version'>
      <PageTitle title={title} />

      <Paper sx={{ paddingTop: 4 }}>
        <Typography fontFamily={FontFamily.medium} fontSize={FontSizes.xl} textAlign='center'>
          <PeriodPriorityText priority={period.priority} />
        </Typography>

        <PeriodAccordionContent period={period} isPeriodicPrice readonly />
      </Paper>

      <FormButtonsContainer>
        <CancelButton />
      </FormButtonsContainer>
    </Box>
  )
}

export default CenterPeriodsHistoricShow
