import React from 'react'

import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'

import InfoCard from 'components/atoms/info-card'
import H3 from 'components/atoms/h3'
import useGetKpis from 'hooks/queries/prebooks/use-get-kpis'
import { SearchCarparksFormFields } from 'models/prebooks'
import CircularProgress from 'components/atoms/circular-progress'

interface Props {
  filters: SearchCarparksFormFields
}

const PrebooksKeyIndicators: React.FC<Props> = ({ filters }) => {
  const { t } = useTranslation()

  const { response, isLoading } = useGetKpis(filters)

  if (!response) {
    return null
  }

  return (
    <>
      <H3 text={t('Indicadores clave de rendimiento')} />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InfoCard label={t('Número ventas')} value={response.numSales} />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Precio final total')} value={response.totalFinalPrice} showCurrency />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Precio base total')} value={response.totalBasePrice} showCurrency />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Total días reservados')} value={response.totalDaysBooked} />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Media precio por hora')} value={response.averagePricePerHour} showCurrency />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Media días compra antic.')} value={response.averageDaysInAdvance} />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Número de cancelaciones')} value={response.numCancellations} />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Porcentaje de cancelaciones')} value={response.cancellationPercent} showPercentage />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Gastos de gestión')} value={response.fees?.MANAGEMENT_FEES ?? 0} showCurrency />
          </Grid>
          <Grid item xs={4}>
            <InfoCard
              label={t('Importe total de cancelaciones')}
              value={response.cancellationTotalAmout}
              showCurrency
            />
          </Grid>
          <Grid item xs={4}>
            <InfoCard label={t('Precio base diario')} value={response.baseDailyPrice ?? 0} showCurrency />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PrebooksKeyIndicators
