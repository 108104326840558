import { FC } from 'react'

import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import TagIcon from '@mui/icons-material/Tag'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Trans, useTranslation } from 'react-i18next'

import FormGridRow from 'components/atoms/form-grid-row'
import InputIcon from 'components/atoms/input-icon'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerSwitch from 'components/molecules/controller-switch'
import { CENTER_TIMEZONES_OPTIONS, CENTER_TYPE_OPTIONS } from 'config/centers-constants'

import { SectionProps } from '.'

const Section1: FC<SectionProps> = ({ control, errors, readonly }) => {
  const { t } = useTranslation()

  return (
    <Paper elevation={3}>
      <Grid container spacing={2} rowGap={2}>
        <Grid item xs={3}>
          <ControllerInputText
            control={control}
            name='carparkCode'
            error={errors.carparkCode}
            label={t`Código`}
            size='full'
            inputProps={{ endAdornment: <InputIcon Icon={TagIcon} /> }}
            disabled
            mandatory
          />
        </Grid>
        <Grid item xs={6}>
          <ControllerInputText
            control={control}
            name='name'
            error={errors.name}
            label={t`Nombre`}
            size='full'
            inputProps={{ endAdornment: <InputIcon Icon={EmojiTransportationIcon} /> }}
            disabled
            mandatory
          />
        </Grid>
        <Grid item xs={3}>
          <ControllerInputText
            control={control}
            name='province'
            error={errors.province}
            label={t`Provincia`}
            size='full'
            inputProps={{ endAdornment: <InputIcon Icon={LocationOnIcon} /> }}
            disabled={readonly}
            mandatory
          />
        </Grid>

        <Grid container spacing={2} rowGap={2} paddingLeft={2}>
          <Grid item xs={3}>
            <ControllerInputSelect
              control={control}
              name='centerType'
              error={errors.centerType}
              label={t`Tipo centro`}
              size='full'
              options={CENTER_TYPE_OPTIONS}
              disabled={readonly}
              mandatory
            />
          </Grid>
          <Grid item xs={3}>
            <ControllerInputSelect
              control={control}
              name='timezone'
              error={errors.timezone}
              label={t`Zona horaria`}
              size='full'
              options={CENTER_TIMEZONES_OPTIONS}
              disabled={readonly}
              mandatory
            />
          </Grid>
        </Grid>

        <FormGridRow>
          <ControllerSwitch
            label={t`Centro activo`}
            name='active'
            variant='full'
            control={control}
            disabled={readonly}
          />
          <Trans i18nKey='generalSettingsForm.active' />
        </FormGridRow>
      </Grid>
    </Paper>
  )
}

export default Section1
