import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Logo } from 'assets/images/svg/logo.svg'
import { colors } from 'config/theme'
import { ENVIRONMENT, FontFamily, FontSizes } from 'config/constants'
import useGetHealth from 'hooks/queries/health/use-get-health'

import * as S from './styled'

const Footer = (): JSX.Element => {
  const { t } = useTranslation()

  const { response } = useGetHealth()

  const { version } = response || 'No disponible'

  const info = t('Versión {{version}} • Entorno {{env}}', {
    env: ENVIRONMENT,
    version,
    interpolation: { escapeValue: false },
  })

  return (
    <S.FooterContainer data-testid='footer'>
      <Logo width='150' />
      <Typography variant='h2' color={colors.white} fontSize={FontSizes.lg} fontFamily={FontFamily.bold}>
        {`${info}`}
      </Typography>
    </S.FooterContainer>
  )
}

export default Footer
