import React, { useCallback } from 'react'

import { GridEnrichedColDef } from '@mui/x-data-grid'
import { QueryKey } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import EditGridActionCellItem from 'components/atoms/edit-grid-action-cell-item'
import ShowGridActionCellItem from 'components/atoms/show-grid-action-cell-item'
import TextCell from 'components/atoms/text-cell'
import ToggleGridActionCellItem from 'components/atoms/toggle-grid-action-cell-item'
import HistoricGridActionCellItem from 'components/atoms/historic-grid-action-cell-item'
import BasicTable from 'components/molecules/basic-table'
import { CentersFormTab } from 'config/centers-constants'
import { RowHeight, TableColumnWidth, TableTypes } from 'config/constants'
import routes from 'config/routes'
import useCentersPermissions from 'hooks/permissions/use-centers-permissions'
import useUpdateCenter from 'hooks/queries/centers/use-update-center'
import { Center } from 'models/center'

interface Props {
  rows: Center[]
  count: number
  type?: TableTypes
  page?: number
  pageSize?: number
  isLoading?: boolean
  onSortModelChange?: (sortString: string) => void
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  queryKey?: QueryKey
  disabled?: boolean
}

const CentersTable: React.FC<Props> = ({ queryKey, type = TableTypes.default, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { canWrite, isSectionWritable } = useCentersPermissions()

  const updateCenter = useUpdateCenter(queryKey)

  const navigateToEdit = useCallback(
    (id: number) => type === TableTypes.default && navigate(routes.centersEdit.replace(':id', id.toString())),
    [navigate, type]
  )

  const navigateToShow = useCallback(
    (id: number) => type === TableTypes.default && navigate(routes.centersShow.replace(':id', id.toString())),
    [navigate, type]
  )

  const navigateToHistoric = useCallback(
    (id: number) => navigate(routes.centersHistoric.replace(':id', id.toString())),
    [navigate]
  )

  const handleToggleIdentityActive = useCallback(
    ({ active, id }: Center) => {
      updateCenter.mutate({ id, active: !active })
    },
    [updateCenter]
  )

  const getTableActions = useCallback(
    ({ row }: { row: Center }) => {
      const actions = [
        <ShowGridActionCellItem key={`${row.id}-show`} onClick={() => navigateToShow(row.id)} />,
        <HistoricGridActionCellItem key={`${row.id}-historic`} onClick={() => navigateToHistoric(row.id)} />,
      ]

      if (isSectionWritable(CentersFormTab.generalSettings)) {
        actions.unshift(
          <ToggleGridActionCellItem
            checked={row.active}
            key={`${row.id}-toggle`}
            onClick={() => handleToggleIdentityActive(row)}
          />
        )
      }

      if (canWrite) {
        actions.splice(-1, 0, <EditGridActionCellItem key={`${row.id}-edit`} onClick={() => navigateToEdit(row.id)} />)
      }

      return actions
    },
    [canWrite, isSectionWritable, handleToggleIdentityActive, navigateToEdit, navigateToShow, navigateToHistoric]
  )

  const columns: GridEnrichedColDef[] = [
    {
      field: 'id',
      headerName: '',
      flex: TableColumnWidth.xs,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'carparkCode',
      headerName: `${t('Código')}`,
      flex: TableColumnWidth.small,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: { row: Center }) => <TextCell text={row.carparkCode} />,
    },
    {
      field: 'name',
      headerName: `${t('Nombre')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Center }) => <TextCell text={row.name} />,
    },
    {
      field: 'province',
      headerName: `${t('Provincia')}`,
      flex: TableColumnWidth.large,
      renderCell: ({ row }: { row: Center }) => <TextCell text={row.province} />,
    },
    {
      field: 'centerType',
      headerName: `${t('Tipo')}`,
      flex: TableColumnWidth.small,
      renderCell: ({ row }: { row: Center }) => <TextCell text={`${t(row.centerType)}`} />,
    },
  ]

  if (TableTypes.default === type) {
    columns.push({
      field: 'actions',
      type: 'actions',
      flex: TableColumnWidth.large,
      sortable: false,
      getActions: getTableActions,
    })
  }

  return (
    <BasicTable
      {...props}
      type={type}
      rowHeight={RowHeight.large}
      columns={columns}
      onRowClick={({ row }) => (canWrite ? navigateToEdit(row.id) : navigateToShow(row.id))}
      disabled={props.disabled}
    />
  )
}

export default CentersTable
