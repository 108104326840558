import { AxiosResponse } from 'axios'

import { Alert, GetAlerts } from 'models/alert'
import { adminApi } from 'services'
import { removeEmpty } from 'utils/remove-empty'

export const getAlerts = ({ filters, ...payload }: GetAlerts): Promise<AxiosResponse<Alert[]>> =>
  adminApi.get('/alerts', {
    params: removeEmpty({ ...payload, ...filters }),
  })

export const updateOneAlert = (id: number, isRead: boolean): Promise<AxiosResponse<Alert>> =>
  adminApi.put(`/alerts/${id}`, { isRead })
