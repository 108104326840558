import { FC } from 'react'

import { Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import BasicInputText from 'components/atoms/basic-input-text'
import FormGridRow from 'components/atoms/form-grid-row'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerSwitch from 'components/molecules/controller-switch'
import CurveType from 'models/curve-type'
import ProfilesSearcher from 'components/molecules/profiles-searcher'
import { PRICING_TYPE_OPTIONS, ROUNDING_OPTIONS } from 'config/centers-constants'
import { CenterPricingType } from 'models/center'
import { ControllerField } from 'models/form'

import { FormSection, SectionProps } from '.'

const Section2: FC<SectionProps & { curveName: string }> = ({ control, errors, readonly, curveName, watch }) => {
  const { t } = useTranslation()
  const [pricingTypeWatch] = watch(['pricingType'])

  return (
    <FormSection>
      <FormGridRow>
        <ControllerInputSelect
          control={control}
          name='rounding'
          error={errors.rounding}
          label={t`Redondeo precio`}
          size='full'
          options={ROUNDING_OPTIONS}
          disabled={readonly}
          mandatory
        />
        <Trans i18nKey='generalSettingsForm.rounding' />
      </FormGridRow>
      <FormGridRow className='controller-switch'>
        <ControllerSwitch
          label={t`Mostrar redondeo`}
          name='showRounding'
          variant='full'
          control={control}
          disabled={readonly}
        />
        <Trans i18nKey='generalSettingsForm.showRounding' shouldUnescape />
      </FormGridRow>

      <FormGridRow>
        <ControllerInputSelect
          control={control}
          name='pricingType'
          error={errors.pricingType}
          label={t`Tipo de precio`}
          size='full'
          options={PRICING_TYPE_OPTIONS}
          disabled={readonly}
          mandatory
        />
        <Trans i18nKey='generalSettingsForm.pricingType' />
      </FormGridRow>

      {pricingTypeWatch === CenterPricingType.DAILY && (
        <>
          <FormGridRow>
            {readonly ? (
              <BasicInputText
                mandatory
                disabled
                field={{ value: curveName } as ControllerField}
                label={t('Curva')}
                size={'full'}
              />
            ) : (
              <Controller
                control={control}
                name='curveId'
                disabled={readonly}
                render={({ field: { ref, ...field } }) => (
                  <ProfilesSearcher {...field} curveType={CurveType.DISCOUNT} error={errors.curveId} mandatory />
                )}
              />
            )}
            <Trans i18nKey='generalSettingsForm.curve' shouldUnescape />
          </FormGridRow>
          <FormGridRow className='controller-switch'>
            <ControllerSwitch
              label={t`Mostrar dto. volumen`}
              name='showVolumeDiscount'
              variant='full'
              control={control}
              disabled={readonly}
            />
            <Trans i18nKey='generalSettingsForm.showVolumeDiscount' shouldUnescape />
          </FormGridRow>
        </>
      )}

      {pricingTypeWatch === CenterPricingType.PERIOD && (
        <FormGridRow>
          <ControllerSwitch
            label={t`Precios futuros`}
            name='futurePrices'
            variant='full'
            control={control}
            disabled={readonly}
          />
          <Trans i18nKey='generalSettingsForm.futurePrices' shouldUnescape />
        </FormGridRow>
      )}
    </FormSection>
  )
}

export default Section2
