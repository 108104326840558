// PREBOOKS
export const GET_CARPARKS = 'getCarparks'
export const GET_KPIS = 'getKpis'
export const GET_EVOLUTION = 'getEvolution'
export const GET_DAILY_OCCUPATION = 'getDailyOccupation'

// CENTERS
export const GET_CENTERS = 'getCenters'
export const GET_ONE_CENTER = 'getOneCenter'
export const GET_CALENDAR = 'getCalendar'
export const GET_PERIODS = 'getCenterPeriods'
export const GET_CENTERS_VERSIONS = 'getCentersVersions'
export const GET_ONE_CENTER_VERSION = 'getOneCenterVersion'
export const GET_CENTER_PERIOD_VERSIONS = 'getCenterPeriodVersions'
export const GET_ONE_CENTER_PERIOD_VERSION = 'getOneCenterPeriodVersion'
export const GET_CENTER_DAY_VERSIONS = 'getCenterDayVersions'
export const GET_ONE_CENTER_DAY_VERSION = 'getOneCenterDayVersion'

// ALERTS
export const GET_ALERTS = 'getAlerts'

// PROFILES
export const GET_PROFILES = 'getProfiles'
export const GET_ONE_PROFILE = 'getOneProfile'
export const GET_PROFILES_VERSIONS = 'getProfilesVersions'
export const GET_ONE_PROFILE_VERSION = 'getOneProfileVersion'

// USERS
export const GET_USERS = 'getUsers'
export const GET_ONE_USER = 'getOneUser'

// APPLICATIONS
export const GET_APPLICATIONS = 'getApplications'
export const GET_ONE_APPLICATION = 'getOneApplication'
export const GET_APPLICATION_TOKEN = 'getApplicationToken'

// EVENTS
export const GET_EVENTS = 'getEvents'
export const GET_ONE_EVENT = 'getOneEvent'

// SIMULATOR
export const GET_SIMULATED_PREBOOK = 'getSimulatedPrebook'

// IMPORT JOBS
export const GET_IMPORT_JOBS = 'getImportJobs'

// RELATIONS
export const GET_RELATIONS = 'getUsersRelations'
export const GET_ROLES_RELATIONS = 'getRolesRelations'
export const GET_ONE_ROLE_RELATION = 'getOneRoleRelation'
export const GET_ONE_USER_RELATION = 'getOneUserRelation'
export const GET_ROLE_RELATION_VERSIONS = 'getRoleRelationVersions'
export const GET_USER_RELATION_VERSIONS = 'getUserRelationVersions'

// HEALTH
export const GET_HEALTH = 'getHealth'
