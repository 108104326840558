import { AxiosResponse } from 'axios'

import { Center } from 'models/center'
import { EvolutionItem, PrebooksExportFilters, GetCarparks, GetEvolution, GetKpis, Kpis } from 'models/prebooks'
import { adminApi } from 'services'

export const getCarparks = ({ search, sort }: GetCarparks): Promise<AxiosResponse<Center[]>> =>
  adminApi.get('/centers', {
    params: { search, sort, active: true },
  })

export const getKpis = ({ from, to, carparkCode, channelCodes, centerType }: GetKpis): Promise<AxiosResponse<Kpis>> =>
  adminApi.get('/purchases/kpis', {
    params: { from, to, carparkCode, channelCodes, centerType },
  })

export const getEvolution = ({
  from,
  to,
  carparkCode,
  channelCodes,
  centerType,
}: GetEvolution): Promise<AxiosResponse<EvolutionItem[]>> =>
  adminApi.get('/purchases/evolution', {
    params: { from, to, carparkCode, channelCodes, centerType },
  })

export const getDailyOccupation = ({ from, to, carparkCode }: GetEvolution): Promise<AxiosResponse<EvolutionItem[]>> =>
  adminApi.get('/purchases/daily-occupation', {
    params: { from, to, carparkCode },
  })

export const exportPurchases = (params: PrebooksExportFilters): Promise<AxiosResponse<Blob>> =>
  adminApi.get('/purchases/export', {
    params,
    responseType: 'blob',
  })
