import { useQuery } from '@tanstack/react-query'

import { GET_CENTER_DAY_VERSIONS } from 'config/queries'
import { CenterDayVersion, GetDayVersions, UseGetDayVersions } from 'models/center'
import { getCenterDayVersions } from 'services/centers'

const useGetCenterDayVersions = ({ id, limit, offset, sort }: GetDayVersions): UseGetDayVersions => {
  const queryKey = [GET_CENTER_DAY_VERSIONS, id, limit, offset, sort]
  const { data: response, isLoading } = useQuery({
    queryKey,
    queryFn: () => getCenterDayVersions({ id, limit, offset, sort }),
    refetchOnMount: true,
    enabled: limit !== undefined && offset !== undefined && sort !== undefined,
  })

  const result: CenterDayVersion[] = response?.data || []
  const count: number = parseInt(response?.headers?.['x-total-count'] || 0)

  return { response: result, count, isLoading }
}

export default useGetCenterDayVersions
